import { media, styled } from 'styles';

const Line = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${media.mobile} {
    flex-direction: column;
    margin-top: 0 !important;
  }
`;

export default Line;
