import useSwrQueryGenerator from './useSwrQueryGenerator';
import { getContentApiUrl } from 'modules/shared/utils/api-constants';

export default function useContentQueryImmutable(
  url,
  params = {},
  swrConfig = {},
  recaptcha = null
) {
  const queryParams = new URLSearchParams(params).toString();
  const completeUrl = `${getContentApiUrl(url)}${queryParams &&
    `?${queryParams}`}`;

  return useSwrQueryGenerator(
    completeUrl,
    {
      ...swrConfig,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
    recaptcha
  );
}
