import React from 'react';
import { FaFaceFrown } from 'react-icons/fa6';
import { styled } from 'styles';
import Line from './Line';

function QuestionNotFoundInfo() {
  return (
    <Line style={{ marginTop: '10px', marginBottom: '5px' }}>
      <NotFoundMessage>
        Não encontramos sua questão{' '}
        <FaFaceFrown style={{ marginLeft: '3px' }} />
      </NotFoundMessage>
    </Line>
  );
}

const NotFoundMessage = styled.p`
  display: flex;
  align-items: center;
  margin: auto;
  color: #5d5d5d;
  font-weight: bold;
`;

export default QuestionNotFoundInfo;
