import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FiEdit3 } from 'react-icons/fi';
import { useSWRConfig } from 'swr';

import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { getContentApiUrl } from 'modules/shared/utils/api-constants';

const ListCardName = ({
  list,
  theme,
  onListUpdated,
  isRenaming,
  setIsRenaming,
}) => {
  const inputRef = useRef(null);
  const textRef = useRef(null);
  const { sendPutRequest } = useContentRestRequest();
  const [isSavingName, setIsSavingName] = useState(false);
  const [optimisticName, setOptimisticName] = useState(null);
  const { mutate } = useSWRConfig();

  const defaultName = 'Lista sem nome';
  const displayName = optimisticName || list.linkedUser.name || defaultName;
  const [newName, setNewName] = useState(displayName);

  const getListCacheKeys = useCallback(listId => {
    const collectionKey = getContentApiUrl('/list-with-statements');
    const individualKey = getContentApiUrl(`/list-with-statements/${listId}`);

    return [collectionKey, individualKey];
  }, []);

  const invalidateListCaches = useCallback(async () => {
    const cacheKeys = getListCacheKeys(list.id);
    await Promise.all(
      cacheKeys.map(key => mutate(key, undefined, { revalidate: true }))
    );
    onListUpdated();
  }, [mutate, list.id, getListCacheKeys, onListUpdated]);

  useEffect(() => {
    if (!optimisticName) {
      setNewName(list.linkedUser.name || defaultName);
    }
  }, [list, defaultName, optimisticName]);

  const handleEditClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsRenaming(true);
  };

  const handleNameChange = e => {
    setNewName(e.target.value);
  };

  const restoreName = () => {
    setOptimisticName(null);
    setNewName(list.linkedUser.name || defaultName);
    setIsRenaming(false);
  };

  const handleKeyDown = async e => {
    if (e.key === 'Enter' && !isSavingName) {
      e.preventDefault();
      await saveListName();
    } else if (e.key === 'Escape') {
      restoreName();
    }
  };

  const saveListName = async () => {
    const trimmedName = newName.trim();

    if (trimmedName === '' || trimmedName === defaultName) {
      restoreName();
      return;
    }

    try {
      setIsSavingName(true);
      setOptimisticName(trimmedName);
      setIsRenaming(false);

      await sendPutRequest(
        `/list-with-statements/linked-users/${list.linkedUser.id}`,
        { name: trimmedName }
      );

      await invalidateListCaches();
    } catch (error) {
      console.error('Error updating list name:', error);
      restoreName();
    } finally {
      setIsSavingName(false);
    }
  };

  useEffect(() => {
    if (isRenaming) {
      inputRef.current.focus();
    }
  }, [isRenaming]);

  if (isRenaming) {
    return (
      <div
        className="flex items-center justify-center"
        style={{ color: theme.color }}
      >
        <input
          ref={inputRef}
          type="text"
          value={newName}
          onChange={handleNameChange}
          onKeyDown={handleKeyDown}
          maxLength={100}
          className="bg-transparent outline-none text-center border-0 leading-5 p-0 m-0 underline flex-1 overflow-hidden text-ellipsis"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onBlur={() => !isSavingName && setIsRenaming(false)}
          disabled={isSavingName}
          style={{
            color: theme.color,
            fontSize: '1rem',
            cursor: isSavingName ? 'wait' : 'text',
            opacity: isSavingName ? 0.7 : 1,
            width: '100%',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="flex items-center justify-center"
      style={{ color: theme.color }}
    >
      <span
        ref={textRef}
        className="text-center leading-5 text-ellipsis overflow-hidden"
        style={{
          fontSize: '1rem',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {displayName !== defaultName ? (
          optimisticName || displayName
        ) : (
          <span className="flex items-center">
            {defaultName}
            <FiEdit3
              className="ml-2 cursor-pointer opacity-70 hover:opacity-100 transition-opacity duration-200"
              onClick={handleEditClick}
              size={20}
            />
          </span>
        )}
      </span>
    </div>
  );
};

ListCardName.propTypes = {
  list: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    linkedUser: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }).isRequired,
  onListUpdated: PropTypes.func,
};

export default ListCardName;
