import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styles';
import Line from './Line';

function Topic({ topic, question, showAiSolution }) {
  if (!topic) {
    return (
      <Line>
        <QuestionLabel>Questão {question.label}</QuestionLabel>
        <QuestionFoundInfo
          question={question}
          showAiSolution={showAiSolution}
        />
      </Line>
    );
  }

  return (
    <>
      <Line>
        <Container>
          <b>Tópico:</b> {topic.name} |{' '}
          <a
            href={`/aprender/topico/${topic.subjectId}/${topic.id}/teoria/${topic.theory?.id}`}
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            Revisar Tópico
          </a>{' '}
        </Container>
        <QuestionFoundInfo
          question={question}
          showAiSolution={showAiSolution}
        />
      </Line>

      <Line>
        <QuestionLabel>Questão {question.label}</QuestionLabel>
      </Line>
    </>
  );
}

Topic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    subjectId: PropTypes.number.isRequired,
    theory: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  question: PropTypes.shape({
    label: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  showAiSolution: PropTypes.bool.isRequired,
};

const Container = styled.p`
  font-size: 1.1em;
  a {
    text-decoration: underline;
    color: #f7ac3d;
    cursor: pointer;
  }
`;

const QuestionLabel = styled.h1`
  font-size: 1.8em;
  font-weight: bold;
  color: #5d5d5d;

  @media ${media.mobile} {
    margin-top: 10px;
  }
`;

const QuestionTag = styled.div`
  width: 180px;
  text-align: center;
  padding: 10px 0;
  border-radius: 13px;
  background-color: ${({ color }) => color};
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;

  @media ${media.mobile} {
    margin-top: 10px;
    font-size: 1em;
    padding: 10px;
  }
`;

function QuestionFoundInfo({ question, showAiSolution }) {
  if (showAiSolution)
    return (
      <QuestionTag color="#6a0d91" style={{ width: '180px' }}>
        Resolvida por IA
      </QuestionTag>
    );

  if (question.status === 'found')
    return (
      <QuestionTag color="#38a725">Resolvida pelo Responde Aí</QuestionTag>
    );

  return <QuestionTag color="#d0cec8">Não Resolvida</QuestionTag>;
}

export default Topic;
