import React from 'react';
import { SWRConfig } from 'swr';
import {
  UserProvider,
  UniversityProvider,
  DialogProvider,
  BannerProvider,
  RollbarProvider,
  FeatureFlagProvider,
} from './';

export default function RootProviders({ children }) {
  return (
    <RollbarProvider>
      <SWRConfig value={{ provider: () => new Map() }}>
        <UserProvider>
          <FeatureFlagProvider>
            <DialogProvider>
              <BannerProvider>
                <UniversityProvider>{children}</UniversityProvider>
              </BannerProvider>
            </DialogProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </SWRConfig>
    </RollbarProvider>
  );
}
