import React, { useMemo } from 'react';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { media, styled } from 'styles';

import { useSolveQuestionWithAi } from 'modules/mvp/hooks/useSolveQuestionWithAi';
import { MathpixMarkdownContent } from 'modules/shared/components/generic';
import { removeImagesFromMd } from '../../../utils';
import Topic from './Topic';
import Line from './Line';
import QuestionNotFoundInfo from './QuestionNotFoundInfo';

export function Question({ question, list }) {
  const topic = question.exercise?.topic;
  const {
    isQuestionEligible: isQuestionEligibleToBeSolvedByAi,
    isListEligible: isListEligibleToBeSolvedByAi,
  } = useSolveQuestionWithAi();

  const showAiSolution = useMemo(() => {
    return (
      isListEligibleToBeSolvedByAi(list) &&
      isQuestionEligibleToBeSolvedByAi(question)
    );
  }, [
    isListEligibleToBeSolvedByAi,
    isQuestionEligibleToBeSolvedByAi,
    list,
    question,
  ]);

  const solutionCta = useMemo(() => {
    if (showAiSolution) return 'Ver resolução';
    if (question.status === 'found') return 'Ver resolução';

    return 'Quero ver questões parecidas';
  }, [question.status, showAiSolution]);

  const questionLink = useMemo(() => {
    if (showAiSolution)
      return `/lista-de-enunciado/${list.id}/questao/${question.id}/resolver-com-ia`;

    return `/lista-de-enunciado/${list.id}/questao/${question.id}`;
  }, [list, question, showAiSolution]);

  const buttonClassName = useMemo(() => {
    if (showAiSolution) return 'ai fill';
    if (question.status === 'found') return 'fill';

    return '';
  }, [showAiSolution, question]);

  return (
    <Container>
      <Topic
        topic={topic}
        question={question}
        showAiSolution={showAiSolution}
      />

      <Line style={{ marginTop: '10px' }}>
        <MathpixMarkdownContent
          text={removeImagesFromMd(question.statement)}
          id={question.id}
        />
        {question.images.length > 0 && <Image src={question.images[0]} />}
      </Line>

      {question.status === 'notFound' && !showAiSolution && (
        <QuestionNotFoundInfo />
      )}

      <Line
        style={{
          marginTop: question.status === 'found' ? '10px' : '0',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <OpenLink to={questionLink} className={buttonClassName}>
          {solutionCta}
        </OpenLink>
        {question.status === 'notFound' && showAiSolution && (
          <SimilarQuestions
            to={`/lista-de-enunciado/${list.id}/questao/${question.id}`}
          >
            Ver questões parecidas
          </SimilarQuestions>
        )}
      </Line>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #ebe8e8;
  border-radius: 15px;

  @media ${media.mobile} {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
`;

const Image = styled.img`
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;

  @media ${media.mobile} {
    margin: 0 auto 10px auto;
  }
`;

const commonStyles = css`
  position: relative;
  padding: 10px 40px;
  border: 1px solid #f7ac3d;
  font-size: 1.2em;
  font-weight: bold;
  color: #f7ac3d;
  border-radius: 60px;
  cursor: pointer;
  position: relative;

  img {
    width: 30px;
    position: absolute;
    right: -40px;
    top: 3px;
    @media ${media.mobile} {
      right: -35px;
      top: 2px;
    }
  }

  &.fill {
    background-color: #f7ac3d;
    color: #fff;
  }

  &.ai {
    background-color: #6a0d91;
    border-color: #6a0d91;
    color: #fff;
  }

  @media ${media.mobile} {
    text-align: center;
    font-size: 1em;
  }
`;

const OpenLink = styled(Link)`
  ${commonStyles}
`;

const SimilarQuestions = styled(Link)`
  margin-top: 10px;
  text-decoration: underline;
  font-size: 0.9em;
  cursor: pointer;
`;
